import React, { Component } from 'react'
import { graphql } from 'gatsby'

import HomePage from '../../components/HomePage'

export default class Argentina extends Component {
  render () {
    const { location, data } = this.props

    return <HomePage location={location} data={data} />
  }
}

export const pageQuery = graphql`
query  ($country: String="AR") {
  ...Country
}`
